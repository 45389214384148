@import '../../utils/mixins';

.worksSection {
    height: 100%;
    animation: apparition 1s linear;

    &_serieList {
        width: 80%;
        margin: auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
        column-gap: 20px;
        overflow: auto;

        &_item {
            width: 300px;

            &_image {
            width: 100%;
            }
            &_title {
                @include label;
                color: black;
                display: flex;
                justify-content: center;
                margin: auto;
                width: 100%;
            }
        }
    }
}