@import '../../utils/mixins';

.dndItem {

    &:hover {
        cursor:grab;
    }

    &_image {
        width: 180px;
        height: 120px;
        object-fit: cover;
    }
    &_buttons {
        z-index: 2;
        &_supprButton, &_isMainButton {
            @include whiteIconButton;
            z-index: 1;
        }
        &_isMainButton {
            &--isWhite {
                color: $colorLightGrey;
                }
            &--isOrange {
                color: $colorSelection;
            }
        }
    }
}