@import '../../utils/mixins';

.gallery {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 50px auto;
    @media only screen and (max-width:$mobileMaxWidth){
        margin: 15px auto;
    }
    @media only screen and (max-width:$mobileMaxWidth){
        margin: 15px auto;
    }

    &_container{
        width: 100%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 2px;
        row-gap: 0;
        list-style-type: none;

        &_imageBox {
            width: 100%;
            background: none;
            border: none;
            padding: 0;
            height: auto;

                &_image {
                width: 100%;
                aspect-ratio: 1.5;
                object-fit: cover;
            }
        }
    }

    &_title {

        margin: 0 auto;
        @include TitleFont;
        font-size: 30px;
    }

    &_description {
        min-height: 180px;
        width: 80%;
        text-align: justify;
        margin: 40px auto;
        display: flex;
        justify-content: center; 
        align-items: center;
        @include MainFont;
        color: $colorDarkGrey;
        font-size: 18px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            font-size: 15px;
            width: 90%;
            
        };
        @media only screen and (max-width:$mobileMaxWidth){
            font-size: 14px;
            width: 90%;
            margin: 20px auto;
        }
    }
}