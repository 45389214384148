@import '../../utils/mixins';

.error404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;

    &_title {
        @include TitleFont;
        font-size: 200px;

    }
    &_text {
        margin: 40px auto;
        @include MainFont;
        font-size: 20px;
        
    }
}