@import '../../utils/mixins';

.serieEditList {
    display: flex;
    flex-direction: column;

    &_item {
        @include editItems;

        &_title {
        @include editList;
        }

        &_buttons {
            
            &_supprButton, &_modifButton {
            @include blackIconButton;
            font-size: 15px;
                &:hover {
                    color: $colorSelection;
                }
            }
        }

        &_addButton {
            margin: auto;
            @include Button;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                margin-bottom: 20px;
            }
            @media only screen and (max-width:$mobileMaxWidth){
                margin-bottom: 20px;
            }
        }
    }
}

.serieEditForm {
    flex-direction: column;
    position: fixed;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.8);
    
    
    &--displayOff {
        display: none;
    };
    &--displayOn {
        display: flex;
    }

    &_mainContainer {
        overflow: auto;
        padding: 30px;
    }

    &_form {
        width: 550px;
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        
        &_title {
            width: 100%;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &_label {
                @include MainFont;
                color: white;
                font-size: 17px;
                margin-bottom: 5px
            }
            &_input {
                box-sizing: border-box;
                width: 100%;
                @include textareas;
            }
        }

        &_description {
            width: 100%;
            box-sizing: border-box;
            display: flex;
            flex-direction: column;
            margin-bottom: 20px;
            &_label {
                @include MainFont;
                color: white;
                font-size: 17px;
                margin-bottom: 5px
            }
            &_textarea {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                min-height: 100px;
                @include textareas;
                padding-bottom: 10px; 
                box-sizing: border-box;
            }
        }
        &_imageField {
            margin-bottom: 20px;

            &_label {
                @include Button;
                color: $colorDarkGrey;
                font-size: 17px;
                padding: 10px;

                &--displayOn {
                    display: flex;
                };
                &--displayOff {
                    display: none;
                };
            }

            &_sampleSystem {

                flex-direction: column;

                &--displayOn {
                    display: flex;
                }
                &--displayOff {
                    display: none;
                }

                &_image {
                    display: flex;
                    justify-content: center;
                    width: 150px;
                    margin: 20px auto;
                }

                &_buttons {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    margin: auto;


                    button {
                        font-family: 'TitleFont';
                        color: $colorSelection;
                        font-size: 18px;
                        border: none;
                        background: none;

                        &:hover {
                            cursor: pointer;
                            color: $colorLightGrey;
                        }
                    }
                }
            }
        }

        &_buttons {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            &_validateButton, &_cancelButton {
                @include Button;
                width: 48%;
                color: $colorDarkGrey;
                font-size: 17px;
                padding: 7px;
                margin-top: 0;
            }
        }
    }
    &_alertTextStyle {
        &--displayOn {
            @include AlertTextStyle;
        }
        &--displayOff {
            display: none;
        }
    }
}