@import '../../utils/mixins';

.mainImageAside {
    &_image {
        width: 100%;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }
    }
}