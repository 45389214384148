@import '../../utils/mixins';

.bioSection {

    animation: apparition 1s linear;
    height: auto;
    width: 60%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    margin: auto;
    padding-bottom: 10%;
    box-sizing: border-box;

    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        width: 70%;
    }
    @media only screen and (max-width:$mobileMaxWidth){
        width: 90%;
    }

    &_paragraph {
        @include MainFont;
        font-size: 18px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            font-size: 15px;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            font-size: 15px;
        }
    }
}