@import '../../utils/mixins';

.eventsArticle {
    animation: apparition 1s linear;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    
    &_list {
        width: 40%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        list-style-type: none;
        row-gap: 50px;
        margin: 60px auto;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 65%;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            width: 65%;
        }

        &_item {

            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            }
            @media only screen and (max-width:$mobileMaxWidth){
                flex-direction: column;
            }

            &_date {
                display: flex;
                flex-direction: column;
                margin-bottom: 30px;

                @media only screen and (max-width:$mobileMaxWidth){
                    margin-bottom: 0;
                }
               
                &_day {
                    @include TitleFont;
                    font-size: 50px;
                    margin-bottom: 10px;
                    color: $colorDarkGrey;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    }
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 40px;
                    }  
                }

                &_monthAndYear {
                    @include TitleFont;
                    font-size: 20px;
                    margin-bottom: 10px;
                    color: $colorDarkGrey;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    }
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 15px;
                    }
                }

                &_time {
                    @include TitleFont;
                    font-size: 15px;
                    margin-bottom: 5px;
                    color: $colorDarkGrey;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    };
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 10px;
                    }
                }

                &_calendarLink {
                    color: $colorDarkGrey;
                    &:hover {
                        color: $colorSelection;
                    }
                }
            }

            &_misc {
                padding-left: 40px;
            
                &_name {
                    @include MainFont;
                    text-transform: uppercase;
                    font-size: 30px;
                    margin-bottom: 20px;
                    color: $colorDarkGrey;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    }
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 20px;
                    }
                }

                &_description {
                    @include MainFont;
                    font-size: 15px;
                    margin-bottom: 10px;
                    color: $colorDarkGrey;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    }
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 14px;
                    }  
                }

                &_location {
                    @include MainFont;
                    font-size: 13px;
                    margin-bottom: 10px;
                    color: $colorDarkGrey;
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    }
                    @media only screen and (max-width:$mobileMaxWidth){
                    }  
                }

                &_eventUrl {
                    @include MainFont;
                    text-decoration: none;
                    font-size: 15px;
                    color: $colorDarkGrey;

                    &_icon{
                        font-size: 11px;
                        padding-bottom: 1px;
                    }
                }
            }
        }
    }
}