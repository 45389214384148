@import '../../utils/mixins';

.serieEditForm {

    &_overlayImage {
        opacity: 0.5;
        width: 180px;
        height: 120px;        
    }

    &_confirmBox {
        position: fixed;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(0, 0, 0, 0.8);
        flex-direction: column;
        margin: auto;

        &--displayOn {
            display: flex;
        }
        &--displayOff {
            display: none;
        }

        &_label {
            display: flex;
            justify-content: center;
            width: 150px;
            @include MainFont;
            background-color: $colorSelection;
            padding: 12px;
            font-size: 16px;
        }

        &_buttons {
            margin: 10px auto;
            
            button {

                @include TitleFont;
                font-size: 18px;
                border: none;
                background: none;
                color: $colorSelection;

                &:hover {
                    cursor: pointer;
                    color: $colorLightGrey;
                }
            }
        }
    }

    &_alertTextStyle {
        @include AlertTextStyle;
        display: flex;
        justify-content: center;

        &--centered {
            width: 100%;
            margin: auto;
            justify-content: center;
            text-transform: uppercase;
            font-size: 20px;
        }
    }
}

