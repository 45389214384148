/*---------------
----- FONTS -----
---------------*/

@font-face {
    font-family: 'TitleFont';
    src: url('../assets/fonts/gobold/Gobold\ Bold.otf') format('opentype');
    /* Ajouter ici les autres formats de police (.woff2, .ttf, .eot, etc.) pour la compatibilité des navigateurs */
}

@font-face {
    font-family: 'MainFont'; 
    src: url('../assets/fonts/Abel/Abel-Regular.ttf') format('truetype');
}

@mixin TitleFont {
    font-family: 'TitleFont';
    color: $colorDarkGrey;
}

@mixin MainFont {
    font-family: 'MainFont';
    color: $colorDarkGrey;
}

@mixin EditCategorieTitle {
    font-family: 'Open Sans';
    font-size: 15px;
    font-weight: 200;
    color: $colorLightGrey;
    background: $colorDarkGrey;
    padding: 3px 30px;
}

@mixin Button {
    font-size: 14px;
    padding: 10px;
    width: auto;
    display: flex;
    justify-content: center;
    align-content: center;
    margin-top: 20px;
    background: white;
    border: none;
    box-sizing: border-box;
    font-family: 'Open Sans';
    font-weight: 200;
    color: $colorDarkGrey;
    cursor: pointer;
    &:hover {
        background-color: $colorSelection;
        color: $colorLightGrey;
    }
}

@mixin editTitle {
    font-family: 'MainFont';
    font-size: 25px;
    font-weight: 200;
}

@mixin editList {
    font-family: 'MainFont';
    font-size: 20px;
    font-weight: 200;
}

@mixin textareas {
    border: none;
    padding: 10px;
    background-color: rgb(238, 238, 238);
    box-sizing: border-box;
    font-family: 'MainFont';
    font-size: 14px;
}

@mixin editItems {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    margin-bottom: 10px;
}


@mixin whiteIconButton {
    background: rgba($color: #000000, $alpha: 0.5);
    border: none;
    font-size: 15px;
    font-weight: 200;
    color: white;
    cursor: pointer;
}

@mixin blackIconButton {
    background: none;
    border: none;
    font-size: 18px;
    font-weight: 200;
    color: $colorDarkGrey;
    cursor: pointer;
}

@mixin dataForm {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

@mixin label {
    @include MainFont;
    color: white;
    font-size: 17px;
    margin-bottom: 5px
}

@mixin AlertTextStyle {
    @include MainFont;
    color: $colorSelection;
    font-style: italic;
    margin-top: 10px;
    font-size: 15px;
    animation: apparition 0.2s linear;
}

$colorDarkGrey: #424242;
$colorMediumGrey: #757575;
$colorLightGrey: rgb(230, 230, 230);
$colorSelection: #ff9100;

$mobileMaxWidth: 480px;
$padMinWidth: 481px;
$padMaxWidth: 991px;



/*-------------------
----- KEYFRAMES -----
-------------------*/

@keyframes apparition {
    0% { opacity: 0 }
    100% { opacity: 1 }
}

@keyframes apparition_disparition {
    0% { opacity: 0 }
    10% { opacity: 1 }
    70%  { opacity: 1 }
    100% { opacity: 0 }
}