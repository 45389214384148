@import '../../utils/mixins';

.eventsEditForm {
    display: block;
    justify-content: space-between;

    &_list {

        &_item {
            @include editItems;

            &_name {
                @include editList;
            }

            &_buttons {
                display: flex;
                flex-direction: row;
                
                &_supprButton, &_editButton {
                    @include blackIconButton;
                    font-size: 15px;

                    &:hover {
                        color: $colorSelection;
                    }
                }        
            }
        }

        &_addButton {
            margin: auto;
            @include Button;
           
        }
    }
    
    &_form {

        position: fixed;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(0, 0, 0, 0.8);
        flex-direction: column;
        
        &--displayOn {
            display: flex;
        }
        &--displayOff {
            display: none;
        }

        &_container {
            width: 40%;
        }

        &_name {
            @include dataForm;
            &_label {
                @include label;
            }
            &_input {
                box-sizing: border-box;
                width: 100%;
                @include textareas;
            }
        }

        &_description {
            @include dataForm;
            &_label {
                @include label;
            }
            &_textarea {
                width: 100%;
                max-width: 100%;
                min-width: 100%;
                min-height: 100px;
                @include textareas;
                padding-bottom: 10px; 
                box-sizing: border-box;
            }
        }

        &_date {
            @include dataForm;
            &_label {
                @include label;
            }
            &_input {
                box-sizing: border-box;
                width: 100%;
                @include textareas;
            }
        }

        &_location {
            @include dataForm;
            &_label {
                @include label;
            }
            &_input {
                box-sizing: border-box;
                width: 100%;
                @include textareas;
            }
        }

        &_link {
            @include dataForm;
            &_label {
                @include label;
            }
            &_input {
                box-sizing: border-box;
                width: 100%;
                @include textareas;
            }
        }

        &_buttons {

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            width: 100%;

            &_validateButton, &_cancelButton {
                @include Button;
                width: 48%;
                color: $colorDarkGrey;
                font-size: 17px;
                padding: 7px;
                margin-top: 0;
            }
        }
    }

    &_alertTextStyle {
        &--displayOn {
            @include AlertTextStyle;
        }
        &--displayOff {
            display: none;
        }
    }
}