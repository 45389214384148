@import '../../utils/mixins';

.bioEditForm {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        flex-direction: column-reverse;
    };
    @media only screen and (max-width:$mobileMaxWidth){
        flex-direction: column-reverse;
    }  

    &_form {
        width: 48%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 100%;
            margin-top: 20px;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            width: 100%;
            margin-top: 20px;
        }  


        &_biography {
            width: 100%;
            display: flex;
            flex-direction: column;

            &_label {
                @include MainFont;
                font-size: 17px;
                margin-bottom: 10px;
            }

            &_textarea {
                @include textareas;
                width: 100%;
                min-width: 100%;
                max-width: 100%;
                min-height: 100px;
                box-sizing: border-box;
                margin-bottom: 20px;
            }
        }

        &_imageField {

            &_labelEdit {
                display: flex;
                @include Button;
                padding: 9px;
            }

            &_labelAdd {
                @include Button;
                &--displayOn {
                    display: flex;
                }
                &--displayOff {
                    display: none;
                }
            }

            &_sampleSystem {
                width:  100%;
                
                &--displayOn {
                    display: block;
                }
                &--displayOff {
                    display: none;
                }

                &_image {
                    width: 90px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin: auto;
                }
            }
        }


        &_buttons {
            margin: auto;
            height: auto;
            width: auto;
            display: flex;
            flex-direction: row;
            justify-content: space-around;
            column-gap: 10px;

            &_validateButton, &_cancelButton {
                width: auto;
                @include Button;
                margin-top: 10px;
            }

        }

        &_okAlert, &_errorAlert {
            &--displayOn {
                @include AlertTextStyle;
                margin: auto;
                margin-top: 15px;
                display: flex;
                animation: apparition_disparition 4s linear;
            }
            &--displayOff {
                display: none;
            }
        }
    
    }

    &_imageSample {
        width: 48%;
        object-fit: cover;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            width: 100%;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            width: 100%;
        }  
    }
}