@import '../../utils/mixins';

.header {
    height: auto;
    display: flex;
    flex-direction: column;
    margin: 30px auto 0 auto;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        margin: 20px auto 0 auto; 
    };
    @media only screen and (max-width:$mobileMaxWidth){
        margin: 15px auto 0 auto; 
    }

    &_title {
        margin: auto;
        @include TitleFont;
        font-size: 3rem;
        font-weight: 300;
        @media only screen and (max-width:$mobileMaxWidth){
            font-size: 2.3rem;    
        }
    }

    &_nav {
        
        &_list {
            margin: 35px auto;
            justify-content: center;
            column-gap: 30px;
            list-style-type: none;
            text-decoration: none;
            display: flex;
            flex-direction: row;
            font-family: 'Raleway';
            flex-wrap: wrap;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                column-gap: 20px;
            };
            @media only screen and (max-width:$mobileMaxWidth){
                
                flex-direction: column;
                // grid-template-columns: 1fr 1fr 1fr;
                row-gap: 5px;
                justify-content: space-between;
                vertical-align: middle;
                align-items: center;
                margin: 20px auto;
            }

            &_item {
                cursor: pointer;
                text-decoration: none;
                &--displayOn {
                    display: block;
                }
                &--displayOff {
                    display: none;
                }
                @media only screen and (max-width:$mobileMaxWidth){
                    text-align: justify;
                    padding:0;
                    margin:0;
                }
                &_link {
                    font-family: 'MainFont';
                    font-size: 20px;
                    text-decoration: none;
                    &:hover {
                    color: $colorSelection;
                    }
                    &--grey {
                        color: $colorMediumGrey;
                    }
                    &--active {
                        color: $colorSelection;
                    }
                    &--inactive {
                        color: $colorDarkGrey;
                    }
                    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                        font-size: 17px;
                    }
                    @media only screen and (max-width:$mobileMaxWidth){
                        font-size: 22px;
                        color: $colorMediumGrey;    
                    }
                }
                &_instaLink {
                    width: 18px;
                    fill: $colorDarkGrey;

                    &:hover {
                        fill: $colorSelection;
                    }
                }
            }
        }
    }

    &_series {

        display: flex;
        margin: auto;
        justify-content: center;
        align-items: center;

        &--closed {
            display:none;
        }
        &--opened {
            display:flex;
            flex-direction: column;
        }

        &_list {
            width: auto;
            display:flex;
            flex-direction: row;
            justify-content: center;
            column-gap: 20px;
            margin-bottom: 35px;
            
            @media only screen and (max-width:$mobileMaxWidth){
                // flex-direction: column;
                display: flex;
                flex-direction: column;
                grid-template-columns: 1fr 1fr 1fr;
                align-items: center;
                text-align: justify;
                row-gap: 5px;
                margin: 30px 0;

                h3 {
                    font-size: 22px;
                }
            }
            

            &_item {
                text-decoration: none;
                @include MainFont;
                flex-wrap: wrap;

                &--active {
                    color: $colorSelection;
                }
                &--inactive {
                    color: $colorDarkGrey;
                }

                &:hover {
                    color: $colorSelection;
                }
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    font-size: 15px;
                    border-top: solid 1px $colorDarkGrey;
                    padding-top: 10px;
                };
                @media only screen and (max-width:$mobileMaxWidth){
                    font-size: 17px;
                    border-top: solid 1px $colorDarkGrey;
                    padding-top: 10px;
                }
            }
            

        }
    } 
    
    &_logOutConfirmBox {
        z-index: 1;
        position: fixed;
        align-items: center;
        justify-content: center;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background:rgba(0, 0, 0, 0.8);
        flex-direction: column;
        margin: auto;

        &--displayOn {
            display: flex;
        }
        &--displayOff {
            display: none;
        }

        &_label {
            display: flex;
            justify-content: center;
            width: 250px;
            @include MainFont;
            background-color: $colorSelection;
            padding: 12px;
            font-size: 16px;
        }

        &_buttons {
            margin: 10px auto;    
            button {
                @include TitleFont;
                font-size: 18px;
                border: none;
                background: none;
                color: $colorSelection;
                margin: 0 7px;

                &:hover {
                    cursor: pointer;
                    color: $colorLightGrey;
                }

            }
        }
    }
}