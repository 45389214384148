@import '../../utils/mixins';

.imageModalAside {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background:rgba(0, 0, 0, 0.8);
    flex-direction: row;
    margin: auto;
    justify-content: space-between;
    padding: 0 20px;
    box-sizing: border-box;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        padding: 0;
        flex-direction: column;
    };
    @media only screen and (max-width:$mobileMaxWidth){
        padding: 0;
        flex-direction: column;
    }

    &--displayOff {
        display: none;
    }

    &--displayOn {
        display: flex;
    }

    &_figure {
        width: 100%;
        height: 100%;

        &_image {
            display: flex;
            align-items: center;
            justify-content: center;
            vertical-align: middle;
            margin: auto;
            padding: auto;
            width: 85%;
            height: 100%;
            object-fit: contain;
            position: relative;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                object-fit: contain;
                width: 100%;
            };
            @media only screen and (max-width:$mobileMaxWidth){
                object-fit: contain;
                width: 100%;
            }
        }
    }

    &_leftButton,  &_rightButton {
        color: white;
        font-size: 20px;
        width: 2%;
        background: none;
        border: none;
        box-sizing: border-box;
        cursor: pointer;
        &:hover {
        color: $colorSelection;
        }

        &--displayOn {
            display: block;
        }

        &--displayOff {
            display: none;
        }
    }

    &_leftButton {
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            z-index: 1;
            position: absolute;
            bottom:1vh;
            left: 33%;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            z-index: 1;
            position: absolute;
            bottom:1vh;
            left: 33%;
        }
    }
    &_rightButton {
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            z-index: 1;
            position: absolute;
            bottom:1vh;
            right: 33%;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            z-index: 1;
            position: absolute;
            bottom:1vh;
            right: 33%;
        }
    }
}