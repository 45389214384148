@import '../../utils/mixins';

.editAside {
    width: 70%;
    margin: 75px auto;
    align-items: center;
    flex-direction: column;
    row-gap: 75px;
    justify-content: center;

    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        row-gap: 20px;
    }
    @media only screen and (max-width:$mobileMaxWidth){
        row-gap: 20px;
    }
    &--displayOff {
        display: none;
    }

    &--displayOn {
        display: flex;
    }
    
    &_topContainer {
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: row;

        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            flex-direction: column;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            flex-direction: column;
        }

        &_editSeries, &_editEvents {
            width: 47%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            row-gap: 60px;

            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: 100%;
                margin-bottom: 20px;
            }
            @media only screen and (max-width:$mobileMaxWidth){
                width: 100%;
                margin-bottom: 20px;
            }

            h3 {
                box-sizing: border-box;
                @include EditCategorieTitle;
            }
        }
    }

    &_editBio {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        row-gap: 60px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            row-gap: 20px;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            row-gap: 20px;
        }
        h3 {
            @include EditCategorieTitle;
            width: 100%;
            box-sizing: border-box;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                padding: 3px 30px;
                box-sizing: border-box;
            }
            @media only screen and (max-width:$mobileMaxWidth){
                padding: 3px 30px;
                box-sizing: border-box;
            }     
        }
        
    }

    &_notAuthText {

        margin: auto;
        padding-bottom: 25%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        @include MainFont;

        &--displayOff {
            display: none;
        }
        
        &--displayOn {
            display: flex;
        }
    }
}