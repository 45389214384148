@import '../../utils/mixins';

.contactAside {

    height: 42vh;
    
    &_datas {

        width: 30%;
        display: flex;
        flex-direction: column;
        margin: auto;
        justify-content: left;
        align-items: left;

        @include MainFont;

        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            margin-top: 20%;
            width: 40%;
        }
        @media only screen and (max-width:$mobileMaxWidth){
            margin-top: 20%;
            width: 80%;
        }

        &_name {
            padding: 0;
            margin:0;
            font-size:22px;
        }
        &_description {
            padding: 0;
            margin:0;
            font-size:18px;
            color: $colorMediumGrey;
        }
        &_address {
            padding: 0;
            font-size:17px;
            margin: 15px 0;

            &_line {
                padding: 0;
                margin:0;
                line-height: 30%;

                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    line-height: 40%;
                }
                @media only screen and (max-width:$mobileMaxWidth){
                    line-height: 90%;
                }
            }
        }
        &_mail {
            padding: 0;
            margin:0;
            font-size:17px;
        }
        &_tel {
            padding: 0;
            margin:0;
            font-size:17px;
        }
    }
}