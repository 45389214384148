@import '../../utils/mixins';

.seriesSection {
    animation: apparition 1s linear;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
        margin: 10% auto 0 auto;
    };
    @media only screen and (max-width:$mobileMaxWidth){
        margin: 10% auto 0 auto;
    }
    

    &_serieList {
        height: 100%;
        width: auto;
        margin: auto;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        column-gap: 20px;
        row-gap: 20px;
        @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
            column-gap: 10px;
            row-gap: 10px;
            padding: 0;
            box-sizing: border-box;
            margin: auto;
        };
        @media only screen and (max-width:$mobileMaxWidth){
            display: flex;
            flex-direction: column;
            align-items: center;
            row-gap: 5px;
            margin-top: 20px;
        }

        &_item {
            width: 300px;
            aspect-ratio: 1.5;
            position: relative;
            @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                width: auto;
            };
            @media only screen and (max-width:$mobileMaxWidth){
                width: 100%;
                padding: 0 2%;
                box-sizing: border-box;
            }

            &_image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            }

            &_title {
                @include TitleFont;
                position: absolute;
                top: 0;
                left: 0;
                background-color: transparent;
                height: 100%;
                color: $colorLightGrey;
                display: flex;
                justify-content: center;
                text-align: center;
                line-height: 100%;
                align-items: center;
                margin: auto;
                width: 100%;
                background-color: rgba($color: #000000, $alpha: 0.6);
                color: $colorLightGrey;
                cursor: pointer;
                height: 98%;
                &:hover {
                    background-color: transparent;
                    color: transparent;
                    height: 98%;
                }
                @media only screen and (min-width: $padMinWidth) and (max-width: $padMaxWidth){
                    background-color: rgba($color: #000000, $alpha: 0.6);
                    color: $colorLightGrey;
                    width: 100%;
                    height: 98%;
                }
                @media only screen and (max-width:$mobileMaxWidth){
                    width: 96%;
                    height: 100%;
                    margin: 0 auto 0 2%;
                    background-color: rgba($color: #000000, $alpha: 0.6);
                    color: $colorLightGrey;
                }
            }
        }
    }
}